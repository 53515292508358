<template>
  <div>
    <div>
      <div class="item">
        <el-form :model="form" :rules="rules" ref="form" label-width="130px">
          <div style="display: flex;">
            <div class="left">
              <div class="tree-title">
                <div>选择列表</div>
              </div>
              <div style="padding: 5px 10px;">
                <el-input
                  size="small"
                  placeholder="输入关键字进行过滤"
                  suffix-icon="el-icon-search"
                  v-model="filterText"
                  clearable
                >
                </el-input>
              </div>
              <el-tree
                v-if="treeList.length"
                :data="treeList"
                default-expand-all
                node-key="id"
                ref="tree"
                highlight-current
                :props="defaultProps"
                :filter-node-method="filterNode"
                @check-change="handleNodeCheckedChange"
                :render-content="renderTreeContent"
              >
              </el-tree>
            </div>
            <div class="right">
              <div class="tree-title">
                <div>已选列表</div>
              </div>
              <div class="tree-items1">
                <div class="tree-item1 flex-between" v-for="(item, index) in checkedPersonList" :key="item.id">
                  <div>
                    <i class="el-icon-user" style="color: skyblue;margin-right: 5px;"></i>
                    {{ item.name }}
                  </div>
                  <div>
                    <i
                      class="el-icon-remove-outline"
                      style="color: red;cursor: pointer;"
                      @click="removeTreeItem(item.id)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- <div class="item" style="background: none;">
        <el-row style="text-align: right;">
          <div v-if="!isView" class="dialog-footer">
            <el-button size="small" @click="btnClose">取消</el-button>
            <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm('form')"
              >确定</el-button
            >
          </div>
        </el-row>
      </div> -->
    </div>
  </div>
</template>
<script>
import { sysRoleDeteil } from '@/api/modular/system/roleManage'
import templateTitle from '@/components/templateTitle.vue'
import { _pagePosition, _addPosition, _deletePosition, _editPosition } from '@/api/modular/supervision/projectMag/tab3'
import { _userTree } from '@/api/modular/supervision/projectMag/tab4'
import store from '@/store'
export default {
  props: {
    // treeList: {
    //   type: Array,
    //   default: []
    // },
    // createdUserId: {
    //   type: Array,
    //   default: () => {
    //     return []
    //   }
    // }
  },
  data() {
    return {
      form: {
        name: ''
      },
      title: '',
      isView: false,
      formVisible: false,
      type: '',
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      },
      treeList: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedPersonList: [], // 右侧已选择人员列表
      propsListData: [] //listData父组件传过来的
    }
  },
  created() {},
  mounted() {},
  components: {
    templateTitle
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    async add(id) {
      console.log(id)
      // this.title = '选择部门与人员'
      let params = {
        id
      }
      await this.userTree()
      sysRoleDeteil(params).then(res => {
        console.log(res, '编辑获取数据')
        if (res.code == 200) {
          let listData = []
          listData = res.data.users.length ? res.data.users.map(ele => ele.id) : []
          console.log(listData, 'listdata')
          this.propsListData = listData
          // this.formVisible = true
          if (this.propsListData.length > 0) {
            // 过滤id
            let selectedIds = []
            selectedIds = this.propsListData
            console.log(selectedIds, 'selectids')
            this.$nextTick(() => {
              this.$refs.tree.setCheckedKeys(selectedIds, true)
            })
          }
        }
      })
    },

    // 拉取组织机构信息
    userTree() {
      console.log(params, '拉取组织机构信息userTree')
      let params = {
        // id:null
      }
      _userTree().then(res => {
        if (res.code == 200) {
          this.treeList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    edit(val, type) {
      this.title = '编辑岗位'
      this.type = type
      this.form = val
      // let params = val.id
      // this.detailCarManager(params)
      this.isView = false
      this.formVisible = true
    },
    view(val) {
      return
      this.title = '查看'
      this.form = {}
      let params = val.id
      this.detailCarManager(params)
      this.isView = true
      this.formVisible = true
    },
    showModel() {
      // this.userTree();
      this.formVisible = true
    },
    handleClose(done) {
      this.checkedPersonList = [] // 置空
      this.propsListData = []
      this.treeList = []
      // this.$refs['form'].resetFields()
      done()
    },
    btnClose() {
      this.checkedPersonList = [] // 置空
      this.propsListData = []
      this.treeList = []
      // this.formVisible = false
    },
    // 编辑、增加页面保存方法
    submitForm() {
      if (this.checkedPersonList.length > 0 && this.checkedPersonList.length == 1) {
        let personList = []
        personList = this.checkedPersonList.map(item => {
          return {
            ...item
          }
        })
        this.$emit('getPersonList', personList)
        this.loading = false
        this.btnClose()
      } else {
        this.$message({
          type: 'warning',
          message: '只能选择一位人员！6666666666666'
        })
      }
    },
    // =============
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes())
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys())
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([
        {
          id: 5,
          name: '二级 2-1'
        },
        {
          id: 9,
          name: '三级 1-1-1'
        }
      ])
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3])
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([])
    },
    // 关键字搜索
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // node: 当前点击的树节点数据对象，isChecked：是否被勾选
    // handleNodeCheckedChange(node, isChecked) {
    //   if (isChecked && node.children.length == 0 && node.type == 2) {
    //     // 被勾选，并且当前节点没有子节点，即为叶子节点
    //     this.checkedPersonList.push(node)
    //   } else {
    //     let targetId = node.id
    //     this.checkedPersonList = this.checkedPersonList.filter(item => item.id !== targetId)
    //   }
    // },
    handleNodeCheckedChange(node, isChecked) {
      console.log(node, isChecked, '=========node, isChecked=======')
      if (isChecked && node.children.length == 0 && node.type == 2) {
        // 被勾选，并且当前节点没有子节点，即为叶子节点
        // 取消已勾选节点
        if (this.checkedPersonList.length > 0) {
          this.checkedPersonList[0].isChecked = false
        }
        this.checkedPersonList.push(node)
      } else {
        console.log(this.checkedPersonList, '====this.checkedPersonList1111====')
        let targetId = node.id
        this.checkedPersonList = this.checkedPersonList.filter(item => item.id !== targetId)
      }

      this.$emit('getTreeData', this.checkedPersonList)
      console.log(this.checkedPersonList, '========checkedPersonList===')
    },
    // handleNodeCheckedChange(node, isChecked) {
    //   if (isChecked && node.type === 2) {
    //     // 当前节点为叶子节点
    //     // 取消已勾选节点
    //     if (this.checkedPersonList.length > 0) {
    //       this.checkedPersonList[0].isChecked = false;
    //     }
    //     // 将选中的节点加入选中列表
    //     this.checkedPersonList = [node];
    //   } else {
    //     // 取消选中的节点
    //     this.checkedPersonList = [];
    //   }
    // },
    removeTreeItem(val) {
      this.checkedPersonList = this.checkedPersonList.filter(item => item.id !== val)
      let selectedIds = []
      selectedIds = this.checkedPersonList.map(foo => foo.id)
      this.$refs.tree.setCheckedKeys(selectedIds, true)
    },
    renderTreeContent(h, { node, data }) {
      if (node.level == 0) {
        return <span>{data.name}</span>
      } else {
        if (data.type == 2) {
          return (
            <el-checkbox v-model={node.checked}>
              <span>
                <a-icon type="user" style="color:skyblue;margin-right:10px;" />
                <span>{data.name}</span>
              </span>
            </el-checkbox>
          )
        } else {
          return (
            <span>
              <a-icon type="cluster" style="color:skyblue;margin-right:10px;" />
              <span>{data.name}</span>
            </span>
          )
        }
      }
    }
    // =============
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
::v-deep .el-transfer-panel {
  width: 320px;
  height: 370px;
}
.left {
  width: 48%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.right {
  width: 48%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.tree-title {
  font-size: 16px;
  color: black;
  height: 40px;
  background: #d9e2ec;
  display: flex;
  align-items: center;

  div {
    padding-left: 10px;
  }
}
.tree-items1 {
  height: calc(100% - 40px);
  overflow-y: auto;
  .tree-item1 {
    padding: 0px 0.15rem;
    line-height: 28px;
  }
}
</style>
