/*
 * @Author: awenyjw
 * @Date: 2023-05-08 10:31:46
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-05-09 13:02:55
 * @FilePath: \hc-smartSupervision\src\api\modular\supervision\projectMag\tab4.js
 * @Description: 
 * 
 */

import {
    axios
} from '@/utils/request'
/**
 * @description: 获取当前项目所有岗位
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-08 10:34:53
 */
export function _allPositionRoleNames(params) {
    return axios({
        url: '/sysRole/allpositionrolenames',
        method: 'get',
        params: params
    })
}
/**
 * @description: 获取当前项目岗位（下拉）
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:17:19
 */
export function _pagePositionRole(params) {
    return axios({
        url: '/sysRole/pagepositionrole',
        method: 'get',
        params: params
    })
}
/**
 * @description: 
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-08 11:33:14
 */
export function _userTree(params) {
    return axios({
        url: '/sysOrg/usertree',
        method: 'get',
        params: params
    })
}
/**
 * @description: 增加项目成员
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:23:12
 */
export function _addProjectUser(params) {
    return axios({
        url: '/projectuser/add',
        method: 'post',
        data: params
    })
}
/**
 * @description: 编辑项目成员
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:24:16
 */
export function _editProjectUser(params) {
    return axios({
        url: '/projectuser/edit',
        method: 'post',
        data: params
    })
}
/**
 * @description: 删除项目成员
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:24:46
 */
export function _deleteProjectUser(params) {
    return axios({
        url: '/projectuser/delete',
        method: 'post',
        data: params
    })
}
/**
 * @description: 进/出场
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:25:20
 */
export function _inoutProjectUser(params) {
    return axios({
        url: '/projectuser/inout',
        method: 'post',
        data: params
    })
}
/**
 * @description: 获取项目成员
 * @param {*} id
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 10:26:58
 */
export function _detailsProjectUser(params) {
    return axios({
        url: `/projectuser/detail/${params}`,
        method: 'get'
    })
}
/**
 * @description: 获取项目成员列表
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-09 13:10:00
 */
export function _pageProjectUser(params) {
    return axios({
        url: '/projectuser/page',
        method: 'get',
        params: params
    })
}